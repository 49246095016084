import React, { useEffect } from 'react'
import '.././static/css/privacyPolicy.css'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { AddLangToPath } from '../helper/AddLanguagePath'

const PrivacyPolicy = () => {
    const { t } = useTranslation()
    document.title = t('privacy_policy.title');

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="privacy-policy-page">
            <div className="policy-header">
                <h1>{t('privacy_policy.title')}</h1>
                <p>{t('privacy_policy.sub_title')}</p>
            </div>
            <div className="privacy-policy">
                <div className="privacy-policy-content">
                    <ol>
                        <li><b>{t('privacy_policy.bullet1.title')}</b>
                            <ol className="custom-list-style">
                                <li>{t('privacy_policy.bullet1.point1')}</li>
                                <li>{t('privacy_policy.bullet1.point2')}</li>
                                <li>{t('privacy_policy.bullet1.point3')}</li>
                            </ol>
                        </li>
                        <li><b>{t('privacy_policy.bullet2.title')}</b>
                            <ol className="custom-list-style">
                                <li>{t('privacy_policy.bullet2.point1')}</li>
                                <li>{t('privacy_policy.bullet2.point2')}</li>
                                <li>{t('privacy_policy.bullet2.point3')}</li>
                            </ol>
                        </li>
                        <li><b>{t('privacy_policy.bullet3.title')}</b>
                            <div>
                            <p><strong>{t('privacy_policy.bullet3.subtitle')}:</strong></p>
                                <ul style={{ listStyleType: 'disc' }}>
                                    <li>{t('privacy_policy.bullet3.point1')}</li>
                                    <li>{t('privacy_policy.bullet3.point2')}</li>
                                    <li>{t('privacy_policy.bullet3.point3')}</li>
                                    <li>{t('privacy_policy.bullet3.point4')}</li>
                                    <li>{t('privacy_policy.bullet3.point5')}</li>
                                    <li>{t('privacy_policy.bullet3.point6')}</li>
                                    <li>{t('privacy_policy.bullet3.point7')}</li>
                                    <li>{t('privacy_policy.bullet3.point8')}</li>
                                </ul>
                            </div>
                        </li>
                        <li><b>{t('privacy_policy.bullet4.title')}</b>
                            <div>
                                <p><strong>{t('privacy_policy.bullet4.subtitle')}</strong></p>
                                <ul style={{ listStyleType: 'disc' }}>
                                    <li>{t('privacy_policy.bullet4.point1')}</li>
                                    <li>{t('privacy_policy.bullet4.point2')}</li>
                                    <li>{t('privacy_policy.bullet4.point3')}</li>
                                    <li>{t('privacy_policy.bullet4.point4')}</li>
                                    <li>{t('privacy_policy.bullet4.point5')}</li>
                                    <li>{t('privacy_policy.bullet4.point6')}</li>
                                    <li>{t('privacy_policy.bullet4.point7')}</li>
                                </ul>
                            </div>
                        </li>
                        <li><b>{t('privacy_policy.bullet5.title')}</b>
                            <p>{t('privacy_policy.bullet5.subtitle')}</p>
                        </li>
                        <li><b>{t('privacy_policy.bullet6.title')}</b>
                            <p>{t('privacy_policy.bullet6.subtitle')}</p>
                        </li>
                        <li><b>{t('privacy_policy.bullet7.title')}</b>
                            <p>{t('privacy_policy.bullet7.subtitle')}</p>
                        </li>
                        <li><b>{t('privacy_policy.bullet8.title')}</b>
                            <p>{t('privacy_policy.bullet8.subtitle')}</p>
                        </li>
                        <li><b>{t('privacy_policy.bullet9.title')}</b>
                            <p>{t('privacy_policy.bullet9.subtitle')}</p>
                        </li>
                        <li><b>{t('privacy_policy.bullet10.title')}</b>
                            <p>{t('privacy_policy.bullet10.subtitle')} <Link to={AddLangToPath('/contact-us')}>{t('privacy_policy.bullet10.link')}</Link>.</p>
                        </li>
                    </ol>
                </div>
                <p className='updteded-date'><b>{t('privacy_policy.date')}:</b> 5 May 2024</p>
            </div>
        </div>
    )
}

export default PrivacyPolicy