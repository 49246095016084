import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import uuid from 'react-uuid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark } from '@fortawesome/free-regular-svg-icons';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Slide } from 'react-slideshow-image';
import '../../../node_modules/react-slideshow-image/dist/styles.css';
import '../../../node_modules/video-react/dist/video-react.css';
import '../../static/css/productPage/video.css';
import VideoPlayer from '../../helper/VideoPlayer';
import { useTranslation } from 'react-i18next';

const Video = ({ video, videoImage, images }) => {
  const { t } = useTranslation();
  const [imageZoom, setImageZoom] = useState(false)
  const [currentImage, setCurrentImage] = useState(0)
  const [modal, setModal] = useState(false);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (imageZoom) {
        if (event.key === 'ArrowRight') {
          document.querySelector('.nav2').click();
        } else if (event.key === 'ArrowLeft') {
          document.querySelector('.nav1').click();
        }
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [imageZoom]);

  const openVideo = () => {
    setModal(!modal);
  }

  const openImage = (index) => {
    setImageZoom(!imageZoom);
    setCurrentImage(index);
  };

  let properties = {
    prevArrow: <button type="button"
      className="nav1 default-nav slider-left-button" data-type="prev" aria-label="Previous Slide">
      <svg width="24" height="24" viewBox="0 0 24 24">
        <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z"></path>
      </svg>
    </button>,
    nextArrow: <button type="button"
      className="nav2 default-nav slider-right-button" data-type="next" aria-label="Next Slide">
      <svg width="24" height="24" viewBox="0 0 24 24">
        <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z"></path>
      </svg>
    </button>,
  };

  return (
    <div className="video-container">
      <div className="video">
        <button style={video?.length > 0 ? { cursor: 'pointer' } : null} className='image-button' onClick={openVideo}>
          <h2>{t('product_page.video')}</h2>
          <LazyLoadImage src={videoImage} alt="Video Thumbnail" />
        </button>
        {modal ?
          (video && video?.length > 0 ?
            <VideoPlayer video={video[0].video} /> : null
          ) : null}

        {/* Image Slider */}
        <div className="product-images">
          <h2>{t('product_page.screenshot')}</h2>
          {images &&
            <Slide slidesToScroll={1} slidesToShow={1} indicators={false} autoplay={false} infinite={false}>
              {images?.map((productImage, index) => {
                return (
                  <div className="screenshots" key={uuid()}>
                    <button className='slider-image-button' onClick={() => { openImage(index) }} >
                      <LazyLoadImage src={productImage?.screenshot} alt={productImage?.screenshot_detail} />
                    </button>
                  </div>
                )
              })}
            </Slide>
          }
        </div>
        {imageZoom ?
          <div className="ImagePopupDiv" id="ImagePopupDiv" style={{ display: "block" }}>
            <div className="InnerImageDiv">
              <Slide
                className="zoomImage"
                {...properties}
                autoplay={false}
                transitionDuration={0}
                slidesToScroll={1}
                slidesToShow={1}
                indicators={false}
                defaultIndex={currentImage}
                infinite={false} >
                {images.map((productImage) => {
                  return (
                    <div className="screenshot" key={uuid()}>
                      <p className='imageText'>{productImage?.screenshot_detail}</p>
                      <button className='image-button-zoom'>
                        <LazyLoadImage src={productImage?.screenshot} alt={productImage?.screenshot_detail} />
                      </button>
                    </div>
                  )
                })}
              </Slide>
              <button className="CloseVideoPopupButton" onClick={openImage}>
                <FontAwesomeIcon icon={faCircleXmark} />
              </button>
            </div>
          </div>
          : ""}
      </div>
    </div>
  )
}

Video.propTypes = {
  video: PropTypes.array,
  videoImage: PropTypes.string,
  images: PropTypes.array
};

export default Video;