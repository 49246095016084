import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./locales/en.json";
import de from "./locales/de.json";
import pt from "./locales/pt.json";
import es from "./locales/es.json";
import it from "./locales/it.json";
import fr from "./locales/fr.json";

i18n
    .use(initReactI18next)
    .init({
        resources: {
            en: { translation: en },
            de: { translation: de },
            pt: { translation: pt },
            es: { translation: es },
            it: { translation: it },
            fr: { translation: fr },
        },
        lng: localStorage.getItem('lang') || 'en',
        fallbackLng: "en",
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;