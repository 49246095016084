import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Fade, Slide } from 'react-slideshow-image';
import uuid from 'react-uuid';
import { Link } from 'react-router-dom';
import '../../static/css/homePage/imageSlider.css';
import { AddLangToPath } from '../../helper/AddLanguagePath';

const ImageSlider = ({ images }) => {

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const downloadImage = {
        "slider_image": "/images/heroslidersectionimg.webp",
        "product_title": "download",
        "product_slug": AddLangToPath("/download")
    };

    if (!images.some(image => image.product_slug === downloadImage.product_slug)) {
        images.unshift(downloadImage);
    }

    useEffect(() => {
        const handleResize = () => {
          setWindowWidth(window.innerWidth);
        };
    
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
      }, []);

    return (
        <div className='image-slider'>
            {windowWidth < 1030 ?
                <div className='image-box'>
                    <LazyLoadImage src="/images/heroslidersectionimg_mobile.png" alt={downloadImage.product_title} />
                </div>
                :
                <Fade key={windowWidth > 1030 ? 'desktop' : 'mobile'} scale={1.4} slidesToScroll={1} slidesToShow={1} indicators={true} arrows={false} duration={7000} transitionDuration={100}>
                    {
                        images.map((imageContent) => (
                            <div key={uuid()}>
                                <Link to={imageContent.product_slug}>
                                    <LazyLoadImage style={{ objectFit: 'cover', width: '100%',}} src={imageContent.slider_image} alt={imageContent.product_title} />
                                </Link>
                            </div>
                        ))
                    }
                </Fade>
            }
        </div>
    )
}

ImageSlider.propTypes = {
    images: PropTypes.array.isRequired
};

export default ImageSlider