import React, { memo, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Slide } from 'react-slideshow-image';
import '../../static/css/resources/videoTutorialsAndManuals.css'
import '../../../node_modules/react-slideshow-image/dist/styles.css';
import '../../../node_modules/video-react/dist/video-react.css';
import ZoomImage from '../../helper/ZoomImage';
import { commonAPI } from '../../api/commonAPI';
import { useTranslation } from 'react-i18next';
import { AddLangToPath } from '../../helper/AddLanguagePath';
import VideoPlayer from '../../helper/VideoPlayer';

const Slider = ({ videosAndImages, openImage, properties }) => {
  const [videoUrl, setVideoUrl] = useState(null);

  const handleVideoPlay = (videoUrl) => {
    setVideoUrl(videoUrl);
  };

  const handleImageOpen = (index, videosAndImages) => {
    setVideoUrl(null);
    openImage(index, videosAndImages);

  };

  const responsiveSettings = [
    {
      breakpoint: 727,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 341,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 0,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ];

  const productNames = [
    "Security Monitor Pro",
    "WebCam Monitor",
    "IP Camera Viewer",
    "My Screen Recorder",
    "My Screen Recorder Pro",
    "Auto FTP Manager",
    "FTP Manager Pro",
    "FTP Manager Lite",
    "Text Speaker Live",
    "Dictation Pro",
    "Document Translator",
    "Paper Scanner Pro"
  ];

  const wrapTextExceptProductNames = (text) => {
    const regex = new RegExp(`(${productNames.join('|')})`, 'gi');
    return text.split(regex).map((segment) => {
      if (productNames.includes(segment)) {
        return <span key={segment.id} style={{ textWrap: 'nowrap' }}>{segment}</span>;
      } else {
        return <span key={segment.id}>{segment}</span>;
      }
    });
  };

  return (
    <>
      {videoUrl && <VideoPlayer video={videoUrl} onClose={() => setVideoUrl(null)} />}
      <Slide
        {...properties}
        slidesToScroll={1}
        slidesToShow={3}
        indicators={false}
        autoplay={false}
        infinite={false}
        responsive={responsiveSettings}>
        {
          videosAndImages.map((videoOrImage) => {
            return (
              <button className='tutorial-image' key={videoOrImage.id} onClick={() => {
                if (videoOrImage.video) {
                  handleVideoPlay(`${process.env.REACT_APP_BACKEND_URL}${videoOrImage.video}`);
                } else {
                  const imagesOnly = videosAndImages.filter(item => !item.video);
                  const imageIndex = imagesOnly.findIndex(item => item.id === videoOrImage.id);
                  handleImageOpen(imageIndex, imagesOnly);
                }
              }} >
                <div>
                  {
                    videoOrImage.video ?
                      <>
                        <p>{wrapTextExceptProductNames(videoOrImage.video_name)}</p>
                        <div>
                          <LazyLoadImage src={`${process.env.REACT_APP_BACKEND_URL}${videoOrImage.video_thumbnail}`} alt={videoOrImage.video_name} />
                          <svg xmlns="http://www.w3.org/2000/svg" width="58" height="58" viewBox="0 0 58 58" fill="none" className='play-icon'>
                            <circle cx="29" cy="29" r="29" fill="red" fillOpacity="1" />
                            <path d="M40.3984 25.536C43.0651 27.0756 43.0651 30.9246 40.3984 32.4642L26.2984 40.6048C23.6318 42.1444 20.2984 40.2199 20.2984 37.1407L20.2984 20.8595C20.2984 17.7803 23.6318 15.8558 26.2984 17.3954L40.3984 25.536Z" fill="white" fillOpacity="1" />
                          </svg>
                        </div>
                      </>
                      :
                      <>
                        <p>{wrapTextExceptProductNames(videoOrImage.screenshot_detail)}</p>
                        <LazyLoadImage src={`${process.env.REACT_APP_BACKEND_URL}${videoOrImage.screenshot}`} alt={videoOrImage.screenshot_detail} />
                      </>
                  }
                </div>
              </button>
            )
          })
        }
      </Slide>
    </>
  )
}

const VideoTutorialsAndManuals = () => {
  const { t } = useTranslation();
  document.title = t('resources.video_tutorials.title');

  const buttonStyle = {
    background: '#cdcdcd99',
    width: '2.8125vw',
    height: '2.8125vw',
    border: '0',
    textAlign: 'center',
    color: '#fff',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  let properties = {
    prevArrow: <button type="button"
      className="nav1 default-nav" data-type="prev" aria-label="Previous Slide"
      style={{ ...buttonStyle, left: '-5%', top: '55%', height: '3vw', width: '3vw' }}>
      <svg width="24" height="24" viewBox="0 0 24 24">
        <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z"></path>
      </svg>
    </button>,
    nextArrow: <button type="button"
      className="nav2 default-nav" data-type="next" aria-label="Next Slide"
      style={{ ...buttonStyle, right: '-5%', top: '55%', height: '3vw', width: '3vw' }}>
      <svg width="24" height="24" viewBox="0 0 24 24">
        <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z"></path>
      </svg>
    </button>,
  };

  const [modal, setModal] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);
  const [imageData, setImageData] = useState()
  const [videoTutorialData, setVideoTutorialData] = useState();

  useEffect(() => {
    window.scrollTo(0, 0);
    commonAPI('video-tutorial-manual').then((result) => setVideoTutorialData(result));
  }, []);

  const openImage = (index, data) => {
    setModal(!modal);
    setCurrentImage(index);
    setImageData(data);
  };

  const closeImage = () => {
    setModal(false);
  };

  let tutorialData = null

  if (videoTutorialData) {
    return (
      <>
        <div className='tutorial-header'>
          <h1>{t('resources.video_tutorials.title')}</h1>
          <p>{t('resources.video_tutorials.description')}<Link to={AddLangToPath('/contact-us')}>{t('resources.video_tutorials.contact')}</Link>.</p>
        </div>
        <div className='tutorial-content'>
          {
            Object.keys(videoTutorialData).map((videoTutorialType) => {
              return (
                <>
                  <div className="tutorial-type">
                    <p key={videoTutorialType.id}>{videoTutorialType}</p>
                  </div>
                  {
                    Object.keys(videoTutorialData[videoTutorialType]).map((videoTutorial) => {
                      tutorialData = videoTutorialData[videoTutorialType][videoTutorial]
                      return (
                        <div className="tutorial" key={videoTutorial.id}>
                          <div className="tutorial-product-name">
                            <Link to={AddLangToPath(`/${tutorialData.product_slug}`)}>
                              <h3>{tutorialData.product_name}</h3>
                            </Link>
                          </div>
                          <div className='tutorial-container'>
                            <div className="manual">
                              {!tutorialData.is_faq ?
                                <Link to={AddLangToPath(`/help/${tutorialData.product_name_slug}/${tutorialData.user_manual_slug}`)}>
                                  <svg xmlns="http://www.w3.org/2000/svg" width="370" height="505" viewBox="0 0 370 505" fill="none">
                                    <path d="M24 55C24 47.268 30.268 41 38 41H92V502H38C30.268 502 24 495.732 24 488V55Z" fill="#FED96A" />
                                    <path d="M95 41H356C363.732 41 370 47.268 370 55V488C370 495.732 363.732 502 356 502H95V41Z" fill="white" />
                                    <path d="M70 466.5C70 467.605 70.8954 468.5 72 468.5C73.1046 468.5 74 467.605 74 466.5H70ZM70 76V466.5H74V76H70Z" fill="black" />
                                    <line x1="2" y1="76.5" x2="50" y2="76.5" stroke="black" strokeWidth="4" strokeLinecap="round" />
                                    <line x1="2" y1="108.5" x2="50" y2="108.5" stroke="black" strokeWidth="4" strokeLinecap="round" />
                                    <line x1="2" y1="140.5" x2="50" y2="140.5" stroke="black" strokeWidth="4" strokeLinecap="round" />
                                    <line x1="2" y1="173.5" x2="50" y2="173.5" stroke="black" strokeWidth="4" strokeLinecap="round" />
                                    <line x1="2" y1="206.5" x2="50" y2="206.5" stroke="black" strokeWidth="4" strokeLinecap="round" />
                                    <line x1="2" y1="238.5" x2="50" y2="238.5" stroke="black" strokeWidth="4" strokeLinecap="round" />
                                    <line x1="2" y1="270.5" x2="50" y2="270.5" stroke="black" strokeWidth="4" strokeLinecap="round" />
                                    <line x1="2" y1="303.5" x2="50" y2="303.5" stroke="black" strokeWidth="4" strokeLinecap="round" />
                                    <line x1="2" y1="336.5" x2="50" y2="336.5" stroke="black" strokeWidth="4" strokeLinecap="round" />
                                    <line x1="2" y1="368.5" x2="50" y2="368.5" stroke="black" strokeWidth="4" strokeLinecap="round" />
                                    <line x1="2" y1="400.5" x2="50" y2="400.5" stroke="black" strokeWidth="4" strokeLinecap="round" />
                                    <line x1="2" y1="433.5" x2="50" y2="433.5" stroke="black" strokeWidth="4" strokeLinecap="round" />
                                    <line x1="2" y1="466.5" x2="50" y2="466.5" stroke="black" strokeWidth="4" strokeLinecap="round" />
                                    <path fillRule="evenodd" clipRule="evenodd" d="M95 41.5H354C361.18 41.5 367 47.3203 367 54.5V488.5C367 495.68 361.18 501.5 354 501.5H95V41.5ZM92 38.5H95H354C362.837 38.5 370 45.6634 370 54.5V488.5C370 497.337 362.837 504.5 354 504.5H95H92H37C28.1634 504.5 21 497.337 21 488.5V54.5C21 45.6635 28.1634 38.5 37 38.5H92ZM92 501.5H37C29.8203 501.5 24 495.68 24 488.5V54.5C24 47.3203 29.8203 41.5 37 41.5H92V501.5Z" fill="black" />
                                    <line x1="135.5" y1="115" x2="328.5" y2="115" stroke="black" strokeWidth="5" strokeLinecap="round" />
                                    <line x1="135.5" y1="190" x2="328.5" y2="190" stroke="black" strokeWidth="5" strokeLinecap="round" />
                                    <line x1="135.5" y1="257" x2="272.5" y2="257" stroke="black" strokeWidth="5" strokeLinecap="round" />
                                    <line x1="135.5" y1="284" x2="249.5" y2="284" stroke="black" strokeWidth="5" strokeLinecap="round" />
                                    <line x1="135.5" y1="310" x2="174.5" y2="310" stroke="black" strokeWidth="5" strokeLinecap="round" />
                                    <line x1="135.5" y1="339" x2="201.5" y2="339" stroke="black" strokeWidth="5" strokeLinecap="round" />
                                    <line x1="135.5" y1="365" x2="182.5" y2="365" stroke="black" strokeWidth="5" strokeLinecap="round" />
                                    <path d="M69 6.20829V36.5C69 38.1569 70.3431 39.5 72 39.5H125C126.657 39.5 128 38.1569 128 36.5V6.08021C128 3.59343 125.148 2.18636 123.175 3.69939L99.8566 21.5766C98.7652 22.4134 97.2446 22.4009 96.1671 21.5463L73.8642 3.8578C71.8979 2.29833 69 3.69866 69 6.20829Z" fill="#E85253" stroke="black" strokeWidth="3" />
                                    <path d="M143 6.20829V36.5C143 38.1569 144.343 39.5 146 39.5H199C200.657 39.5 202 38.1569 202 36.5V6.08021C202 3.59343 199.148 2.18636 197.175 3.69939L173.857 21.5766C172.765 22.4134 171.245 22.4009 170.167 21.5463L147.864 3.8578C145.898 2.29833 143 3.69866 143 6.20829Z" fill="#B1D09D" stroke="black" strokeWidth="3" />
                                    <line x1="187.5" y1="310" x2="226.5" y2="310" stroke="black" strokeWidth="5" strokeLinecap="round" />
                                    <g filter="url(#filter0_d_596_223)">
                                      <path d="M215.691 344.197C214.192 345.758 214.207 348.228 215.724 349.771L239.061 373.51C240.661 375.138 243.297 375.099 244.848 373.424L251.839 365.873C253.318 364.277 253.249 361.791 251.684 360.279L227.886 337.288C226.294 335.75 223.754 335.797 222.221 337.395L215.691 344.197Z" fill="#F9B446" />
                                      <path d="M245 314L310.083 249.414C310.36 249.139 310.672 248.905 311.022 248.733C316.294 246.138 327.766 243.493 335.5 252C345.5 263 341 272.5 340.5 274.5C340.1 276.1 296.333 321.167 274.5 343.5L245 314Z" fill="#F9B446" />
                                      <path d="M245 314L231.61 336.611C230.666 338.205 230.94 340.239 232.273 341.526L248.208 356.921C249.549 358.217 251.608 358.414 253.17 357.396L274.5 343.5L245 314Z" fill="#F9B446" />
                                      <path d="M245 314L231.61 336.611C230.666 338.205 230.94 340.239 232.273 341.526L248.208 356.921C249.549 358.217 251.608 358.414 253.17 357.396L274.5 343.5M245 314L310.083 249.414C310.36 249.139 310.672 248.905 311.022 248.733C316.294 246.138 327.766 243.493 335.5 252C345.5 263 341 272.5 340.5 274.5C340.1 276.1 296.333 321.167 274.5 343.5M245 314L274.5 343.5M227.886 337.288L251.684 360.279C253.249 361.791 253.318 364.277 251.839 365.873L244.848 373.424C243.297 375.099 240.661 375.138 239.061 373.51L215.724 349.771C214.207 348.228 214.192 345.758 215.691 344.197L222.221 337.395C223.754 335.797 226.294 335.75 227.886 337.288Z" stroke="black" strokeWidth="3" />
                                    </g>
                                    <g filter="url(#filter1_d_596_223)">
                                      <path d="M155 425L140.914 433.743C140.639 433.914 140.386 434.117 140.16 434.349L123.259 451.672C121.73 453.239 121.745 455.745 123.294 457.294L131.672 465.672C133.234 467.234 135.766 467.234 137.328 465.672L154.173 448.827C154.39 448.61 154.582 448.368 154.744 448.108L163.5 434L155 425Z" fill="#9EB7CC" />
                                      <path d="M225.347 359.505C223.791 357.863 221.183 357.837 219.594 359.449L155 425L163.5 434L228.747 368.753C230.279 367.221 230.313 364.747 228.822 363.174L225.347 359.505Z" fill="#9EB7CC" />
                                      <path d="M155 425L219.594 359.449C221.183 357.837 223.791 357.863 225.347 359.505L228.822 363.174C230.313 364.747 230.279 367.221 228.747 368.753L163.5 434M155 425L140.914 433.743C140.639 433.914 140.386 434.117 140.16 434.349L123.259 451.672C121.73 453.239 121.745 455.745 123.294 457.294L131.672 465.672C133.234 467.234 135.766 467.234 137.328 465.672L154.173 448.827C154.39 448.61 154.582 448.368 154.744 448.108L163.5 434M155 425L163.5 434" stroke="black" strokeWidth="3" />
                                    </g>
                                    <path d="M134.655 136.955H142.619L151.031 157.477H151.389L159.801 136.955H167.766V167.5H161.501V147.619H161.248L153.343 167.351H149.077L141.173 147.544H140.919V167.5H134.655V136.955ZM179.611 167.5H172.691L183.236 136.955H191.558L202.088 167.5H195.167L187.516 143.935H187.278L179.611 167.5ZM179.179 155.494H195.525V160.535H179.179V155.494ZM232.573 136.955V167.5H226.995L213.706 148.275H213.483V167.5H207.024V136.955H212.692L225.877 156.165H226.145V136.955H232.573ZM258.036 136.955H264.494V156.791C264.494 159.018 263.963 160.967 262.899 162.638C261.845 164.308 260.368 165.611 258.469 166.545C256.57 167.47 254.357 167.933 251.832 167.933C249.296 167.933 247.079 167.47 245.18 166.545C243.281 165.611 241.804 164.308 240.75 162.638C239.696 160.967 239.169 159.018 239.169 156.791V136.955H245.627V156.239C245.627 157.403 245.881 158.437 246.388 159.342C246.905 160.246 247.631 160.957 248.566 161.474C249.5 161.991 250.589 162.25 251.832 162.25C253.085 162.25 254.173 161.991 255.098 161.474C256.033 160.957 256.754 160.246 257.261 159.342C257.778 158.437 258.036 157.403 258.036 156.239V136.955ZM276.333 167.5H269.412L279.957 136.955H288.28L298.809 167.5H291.889L284.238 143.935H283.999L276.333 167.5ZM275.9 155.494H292.247V160.535H275.9V155.494ZM303.746 167.5V136.955H310.204V162.175H323.299V167.5H303.746Z" fill="black" />
                                    <path d="M317 256L279 294" stroke="#FFD695" strokeWidth="4" strokeLinecap="round" />
                                    <path d="M272 300L261 311" stroke="#FFD695" strokeWidth="4" strokeLinecap="round" />
                                    <path d="M247.807 325L239.052 337.859" stroke="#FFD695" strokeWidth="4" strokeLinecap="round" />
                                    <path d="M216 370.972L163.997 422.594" stroke="#D0E8FD" strokeWidth="4" strokeLinecap="round" />
                                    <path d="M143.011 441.048L129.999 453.964" stroke="#D0E8FD" strokeWidth="4" strokeLinecap="round" />
                                    <path d="M326.5 277L275 328.5" stroke="#EF9C17" strokeWidth="4" strokeLinecap="round" />
                                    <defs>
                                      <filter id="filter0_d_596_223" x="202.078" y="244.7" width="146.363" height="149.506" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                        <feOffset dx="-3" dy="10" />
                                        <feGaussianBlur stdDeviation="4" />
                                        <feComposite in2="hardAlpha" operator="out" />
                                        <feColorMatrix type="matrix" values="0 0 0 0 0.5 0 0 0 0 0.307263 0 0 0 0 0 0 0 0 0.25 0" />
                                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_596_223" />
                                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_596_223" result="shape" />
                                      </filter>
                                      <filter id="filter1_d_596_223" x="109.621" y="356.756" width="126.797" height="129.587" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                        <feOffset dx="-3" dy="10" />
                                        <feGaussianBlur stdDeviation="4" />
                                        <feComposite in2="hardAlpha" operator="out" />
                                        <feColorMatrix type="matrix" values="0 0 0 0 0.231389 0 0 0 0 0.327554 0 0 0 0 0.408333 0 0 0 0.25 0" />
                                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_596_223" />
                                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_596_223" result="shape" />
                                      </filter>
                                    </defs>
                                  </svg>
                                  <p>User Manual</p>
                                </Link>
                                :
                                <Link to={AddLangToPath(`/faq/${tutorialData.product_name_slug}/${tutorialData.user_manual_slug}`)}>
                                  {/* <LazyLoadImage className='faq-icon' src={faq} /> */}
                                  <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" version="1.1" viewBox="0 0 340 460">
                                    <g>
                                      <g>
                                        <path strokeWidth="0px" fill="#e2f7fe" d="M170.3,456.8c-48.5,0-97,0-145.5,0-10.4,0-21-7.7-21-22.5,0-97,0-198.3,0-297.3v-68.9c-.4-9-.6-36.4.1-45.5C4.7,13.8,14.2,4.7,23.6,3.9c2.1-.2,4.2-.2,5.7-.2h85c65.7,0,131.5,0,197.2,0,9.9,0,17.1,3.6,22,11,2,3,3.1,6.4,3.3,9.9.4,8.2.5,34.8,0,43.5v74.1c0,97.3,0,196.7,0,292.1,0,14.8-10.6,22.5-21,22.5-48.5,0-97,0-145.5,0Z" />
                                        <path fill="#000" strokeWidth="0px" d="M311.5,4.1c9.1,0,16.4,3,21.6,10.7,2,3,3.1,6.3,3.2,9.7.4,8.5.4,35,0,43.5,0,120.3,0,246.5,0,366.2,0,14.5-10.3,22-20.5,22-48.5,0-97,0-145.5,0s-96.9,0-145.4,0c-10.2,0-20.5-7.5-20.5-22,0-119.7,0-245.9,0-366.2-.4-9.2-.6-36.4.1-45.5C5.2,13.9,14.7,5.2,23.6,4.4c1.9-.2,3.8-.2,5.7-.2,94.1,0,188.1,0,282.2,0,0,0,0,0,0,0M311.5,3.1h0c-66.1,0-133.4,0-198.4,0-27.9,0-55.9,0-83.8,0-1.6,0-3.7,0-5.8.2C13.9,4.3,4.2,13.5,3.5,22.5c-.7,9.1-.5,36.5-.1,45.6v67.5c0,99.4,0,201.2,0,298.6,0,15.1,10.8,23,21.5,23,48.5,0,96.9,0,145.4,0s97,0,145.5,0c10.7,0,21.5-7.9,21.5-23,0-97.6,0-199.5,0-299v-67.2c.5-8.8.4-35.3,0-43.6-.2-3.6-1.3-7.1-3.4-10.2-5-7.5-12.3-11.2-22.4-11.2h0Z" />
                                      </g>
                                      <g opacity=".8">
                                        <path strokeWidth="0px" fill="#3f5c6c" d="M119.5,90.8v11.7h14.3c1.1,0,2.1.4,2.9,1.1.8.7,1.2,1.7,1.2,2.8s-.4,1.8-1.2,2.6c-.8.8-1.7,1.1-2.9,1.1h-14.3v15.4c0,1.1-.4,2.1-1.2,2.9-.8.8-1.8,1.2-2.9,1.2s-2.2-.4-3-1.1c-.8-.8-1.2-1.7-1.2-2.9v-38.3c0-1.1.4-2.1,1.2-2.9s1.7-1.2,2.9-1.2h21.5c1.1,0,2.1.4,2.9,1.1.8.7,1.2,1.7,1.2,2.8s-.4,1.8-1.2,2.6c-.8.8-1.7,1.1-2.9,1.1h-17.3Z" />
                                        <path strokeWidth="0px" fill="#3f5c6c" d="M183.1,126c0,1.1-.4,2.1-1.1,2.8-.8.7-1.6,1.1-2.6,1.1s-1.5-.2-2.2-.7c-.6-.5-1.1-1.1-1.5-2l-3.4-8h-19.8l-3.4,8.1c-.3.8-.8,1.5-1.4,2-.6.5-1.3.7-2.1.7-1.1,0-2-.3-2.6-.9s-.9-1.5-.9-2.5,0-.8.2-1.3l16.5-39.6c.4-.9.9-1.6,1.6-2.1.7-.5,1.5-.7,2.4-.6.8,0,1.5.2,2.2.7.7.5,1.2,1.1,1.5,2l16.3,38.8c.2.6.3,1.1.3,1.6ZM155.8,111.6h13.3l-6.7-15.5-6.6,15.5Z" />
                                        <path strokeWidth="0px" fill="#3f5c6c" d="M233.9,132.6c.8.8,1.2,1.7,1.2,2.7s-.7,2.5-2.2,3.7c-1.5,1.2-3.3,2.1-5.4,2.8-2.1.7-4.1,1.1-5.7,1.1-2.8,0-5.1-.3-6.7-.8-1.7-.6-3.3-1.3-5-2.3-1.1-.7-2-1.1-2.7-1.4-.7-.3-1.5-.4-2.5-.4s-1,0-1.3.2c-.4.1-.8.3-1.4.6-.6.3-1.1.5-1.6.7-.5.2-1.1.2-1.9.2s-2-.4-2.8-1.2c-.8-.8-1.2-1.7-1.2-2.8,0-1.7.8-2.9,2.3-3.4l6.6-2.5c-3.4-.8-6.5-2.2-9.1-4.5-2.7-2.2-4.7-5-6.2-8.3-1.5-3.3-2.2-6.8-2.2-10.6s1-8.4,2.9-12c1.9-3.6,4.6-6.5,8-8.6s7.3-3.2,11.6-3.2,8.1,1.1,11.6,3.2c3.4,2.1,6.1,5,8.1,8.6,1.9,3.6,2.9,7.7,2.9,12s-.7,7.3-2.2,10.6c-1.5,3.3-3.6,6-6.2,8.3-2.7,2.2-5.7,3.7-9.2,4.5l-4,1.5c1,.1,2,.4,2.8.7.9.4,1.8.8,2.9,1.5,1.4.8,2.6,1.3,3.6,1.7,1,.4,2.2.5,3.4.5s2.1-.2,2.8-.5c.8-.3,1.6-.8,2.3-1.4.3-.2.7-.5,1.2-1,.5-.4,1-.7,1.5-1,.4-.2.9-.3,1.3-.3.9,0,1.8.4,2.6,1.2ZM196.4,114.5c1.2,2.4,2.8,4.3,4.9,5.7,2.1,1.4,4.5,2.1,7.2,2.1s5-.7,7.2-2.1c2.1-1.4,3.8-3.3,5-5.7,1.2-2.4,1.8-5.1,1.8-8.1s-.6-5.6-1.8-8.1c-1.2-2.4-2.8-4.3-5-5.7-2.1-1.4-4.5-2.1-7.2-2.1s-5.1.7-7.2,2.1c-2.1,1.4-3.7,3.3-4.9,5.7-1.2,2.4-1.8,5.1-1.8,8.1s.6,5.7,1.8,8.1Z" />
                                      </g>
                                      <g>
                                        <path strokeWidth="0px" fill="#3b97d3" d="M336.3,50c-23.5,0-47,0-70.6,0-85.7,0-171.4,0-257,0-1.5,0-2.9,0-4.4,0-.4-9.2-.6-18.4.1-27.5C5.2,13.9,14.7,5.2,23.6,4.4c1.9-.2,3.8-.2,5.7-.2,94.1,0,188.1,0,282.2,0,9.1,0,16.4,3,21.6,10.7,2,3,3.1,6.3,3.2,9.7.4,8.5.4,17,0,25.5Z" />
                                        <g opacity=".6">
                                          <path strokeWidth="0px" fill="#fff" d="M289.4,33h-17v-16.5h17v16.5ZM276,29.4h9.8v-9.3h-9.8v9.3Z" />
                                          <rect strokeWidth="0px" fill="#fff" x="245.3" y="22.9" width="18" height="3.6" />
                                          <g>
                                            <rect strokeWidth="0px" fill="#fff" x="298.5" y="22.9" width="18" height="3.6" transform="translate(72.6 224.7) rotate(-45)" />
                                            <rect strokeWidth="0px" fill="#fff" x="305.7" y="15.7" width="3.6" height="18" transform="translate(72.6 224.7) rotate(-45)" />
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                    <path strokeWidth="0px" fill="#02408a" d="M124.8,312.5c0,44.5,43.8,80.5,97.8,80.5,12.2,0,23.8-1.8,34.5-5.2,20.5,22.2,49.2,16.1,49.2,16.1-14.1-9.3-18.7-21-19.6-30.6,20.7-14.8,33.8-36.5,33.8-60.8,0-44.5-43.8-80.5-97.8-80.5s-97.8,36-97.8,80.5Z" />
                                    <path strokeWidth="0px" fill="#ffde55" d="M134.7,312.5c0-38.1,39.4-69,87.9-69s87.9,30.9,87.9,69c0,17.9-8.7,34.2-22.9,46.5-4,3.4-8.3,6.5-13.1,9.2.3,3.1,1,7.2,2.1,11.4,1.1,4,2.5,8.1,4.2,11.4,0,0-7.2-1.3-13.5-6.9-2.6-2.3-5-5.4-6.7-9.4-3.4,1.3-7,2.4-10.7,3.4-8.6,2.2-17.8,3.4-27.3,3.4-48.6,0-87.9-30.9-87.9-69Z" />
                                    <g>
                                      <path strokeWidth="0px" fill="#003f8a" d="M284.5,289h-121c-2.5,0-4.5-2-4.5-4.5s2-4.5,4.5-4.5h121c2.5,0,4.5,2,4.5,4.5s-2,4.5-4.5,4.5Z" />
                                      <path strokeWidth="0px" fill="#003f8a" d="M284.5,310.4h-121c-2.5,0-4.5-2-4.5-4.5s2-4.5,4.5-4.5h121c2.5,0,4.5,2,4.5,4.5s-2,4.5-4.5,4.5Z" />
                                      <path strokeWidth="0px" fill="#003f8a" d="M284.5,331.7h-121c-2.5,0-4.5-2-4.5-4.5s2-4.5,4.5-4.5h121c2.5,0,4.5,2,4.5,4.5s-2,4.5-4.5,4.5Z" />
                                      <path strokeWidth="0px" fill="#003f8a" d="M242.6,353h-79.1c-2.5,0-4.5-2-4.5-4.5s2-4.5,4.5-4.5h79.1c2.5,0,4.5,2,4.5,4.5s-2,4.5-4.5,4.5Z" />
                                    </g>
                                    <path strokeWidth="0px" fill="#02408a" d="M119.9,155.9c-54,0-97.8,36-97.8,80.5s13.1,46.1,33.8,60.8c-1,9.6-5.6,21.3-19.6,30.6,0,0,28.7,6.1,49.2-16.1,10.7,3.3,22.4,5.2,34.5,5.2,54,0,97.8-36,97.8-80.5s-43.8-80.5-97.8-80.5Z" />
                                    <path strokeWidth="0px" fill="#a2ebfc" d="M119.9,305.4c-9.5,0-18.7-1.2-27.3-3.4-3.7-.9-7.2-2.1-10.7-3.4-1.7,4-4.1,7-6.7,9.4-6.3,5.6-13.5,6.9-13.5,6.9,1.7-3.3,3.1-7.4,4.2-11.4,1.1-4.2,1.8-8.3,2.1-11.4-4.7-2.7-9.1-5.8-13.1-9.2-14.3-12.3-22.9-28.6-22.9-46.5,0-38.1,39.4-69,87.9-69s87.9,30.9,87.9,69-39.4,69-87.9,69Z" />
                                    <path strokeWidth="0px" fill="#02408a" d="M146.8,213.8c0,8.4-4.1,15.7-12.2,22.1-2.8,2.2-4.7,4.1-5.8,5.8-1.1,1.7-1.7,3.7-1.7,6.2v2h-19v-5c0-6.9,2.3-12.3,6.8-16.2,3-2.5,4.9-4.3,5.7-5.2.8-.9,1.5-1.8,1.8-2.7.4-.9.5-1.9.5-3.1s-.6-2.6-1.9-3.6c-1.3-1-2.9-1.5-4.9-1.5-6.1,0-12.4,2.4-19.1,7.3v-22.2c2.4-1.4,5.8-2.6,9.9-3.6,4.2-1,7.9-1.5,11.2-1.5,9.2,0,16.3,1.8,21.2,5.5,4.9,3.6,7.3,8.9,7.3,15.9ZM131.7,265.9c0,3.4-1.2,6.1-3.6,8.3-2.4,2.1-5.7,3.2-9.8,3.2s-7.2-1.1-9.7-3.3c-2.5-2.2-3.7-4.9-3.7-8.2s1.2-6,3.6-8.1c2.4-2.1,5.7-3.1,9.8-3.1s7.3,1,9.8,3.1c2.5,2.1,3.7,4.7,3.7,8.1Z" />
                                  </svg>
                                  <p className='faq-title'>Frequently Asked Questions</p>
                                </Link>
                              }
                            </div>
                            <div className="tutorial-manual">
                              <div className="player">
                                <Slider videosAndImages={tutorialData.video_and_tutorial.videos_and_screenshots} openImage={openImage} properties={properties} />
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    })
                  }
                </>
              )
            })
          }
        </div>
        {
          modal ?
            <ZoomImage
              imageIndex={currentImage}
              imageData={imageData}
              onClose={closeImage}
            />
            : null
        }
      </>
    )
  }
}

export default memo(VideoTutorialsAndManuals);